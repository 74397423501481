const $ = jQuery;

export default class Places {
    constructor(options = null) {
        this.Places = null;
        this.autocomplete = null;
        this.$latitudeInput = $("input.latitude");
        this.$longitudeInput = $("input.longitude");
        // this.__googleApi();
        this.placesOject = null;
    }

    async __loadGoogleMapsLibrary() {
        const { Places } = await google.maps.importLibrary("places");
        this.Places = Places;
    }

    async init() {
        await this.__loadGoogleMapsLibrary();
        this.__initAutocomplete();
        this.__initFormSubmission();
    }

    __initFormSubmission() {
        if (document.querySelector("#geolocateForm")) {
            const form = document.getElementById("geolocateForm");
            form.addEventListener("submit", (event) => {
                event.preventDefault(); // Prevent default form submission
            });
        }
    }

    __initAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("postcode"),
            { types: ["geocode"] }
        );
        this.autocomplete.setFields(["address_component", "geometry"]);
        this.autocomplete.addListener(
            "place_changed",
            this.__fillInAddress.bind(this)
        );
    }

    __fillInAddress() {
        this.placesOject = this.autocomplete.getPlace();

        this.$latitudeInput.val(this.placesOject.geometry.location.lat());
        this.$longitudeInput.val(this.placesOject.geometry.location.lng());
    }
}
