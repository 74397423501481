import Places from "./places";
import Neighbourhood from "./nbhd-location-finder";

jQuery(function ($) {
    /*
	  (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
	    key: "AIzaSyAbP-_eo20Ev5qkyeV3qD-wBXmcMb0JdtE",
	    v: "weekly",
	    // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
	    // Add other bootstrap parameters as needed, using camel case.
	  });
*/

    $.fancybox.defaults.infobar = false;
    $.fancybox.defaults.buttons = ["close"];

    if ($(".quick-exit").length > 0) {
        $(".quick-exit").click(function (e) {
            e.preventDefault();
            $("body").hide();
            window.open("http://weather.com", "_newtab");
            // Replace current site with another benign site
            window.location.replace("http://google.com");
        });

        $(document).keyup(function (e) {
            if (e.keyCode == 27) {
                e.preventDefault();
                $("body").hide();
                window.open("http://weather.com", "_newtab");
                // Replace current site with another benign site
                window.location.replace("http://google.com");
            }
        });
    }

    //PLACES

    if (document.getElementById("geolocateForm")) {
        const places = new Places({});
        places.init();
    }

    //FORM SUBMISSION

    $("body").on("submit", "#geolocateForm", function (event) {
        event.preventDefault();
        $(".js-empty-error").addClass("d-none");

        var geojson = "";
        var id = "";
        var name = "";
        var permalink = "";
        var noresponse = this.querySelector(".js-no-results");
        var ajaxresponse = this.querySelector(".result-ajax");
        var lng = this.querySelector("input[name=longitude]").value;
        var lat = this.querySelector("input[name=latitude]").value;
        var postcode = this.querySelector("input[name=postcode]");

        if (lat == "" || lng == "") {
            $(".js-empty-error").removeClass("d-none");
            return false;
        }

        const currentDomain = window.location.hostname;
        const apiUrl =
            "https://" + currentDomain + "/wp-json/tessellate/geoJson";

        const neighbourhood = new Neighbourhood(lat, lng, apiUrl);
        neighbourhood.init().then((result) => {
            if (result.isInside) {
                id = result.polygonId;
                name = result.polygonName;
                permalink = result.polygonPermalink;
                ajaxresponse.classList.remove("d-none");
                ajaxresponse.innerHTML =
                    "<p>Your neighbourhood is " +
                    name +
                    '</p><a href="' +
                    permalink +
                    '" class="button">View Action Plan</a>';
            } else {
                postcode.classList.add("field--error");
                noresponse.classList.remove("d-none");
            }
        });
    });

    //CHART JS COUNTER

    $(".countChartjs").each(function () {
        $(this)
            .prop("Counter", 0)
            .animate(
                {
                    Counter: $(this).attr("data-number"),
                },
                {
                    duration: 700,
                    easing: "swing",
                    step: function (now) {
                        if (now % 1 == 0) {
                            $(this).text(Math.ceil(now).toLocaleString("en"));
                        } else {
                            $(this).text(now.toFixed(1));
                        }
                    },
                }
            );
    });

    //modal
    $("body").on("click", ".openModal", function () {
        $(this).parent().parent().next(".modalDash").addClass("show");
    });
    $("body").on("click", ".closeModal", function () {
        $(this).parent().parent().removeClass("show");
    });

    // window.cookieconsent.initialise({
    // 	"palette": {
    // 		"popup": {
    // 			"background": "#57555b", "text": "#ffffff"
    // 		},
    // 		"button": {
    // 			"background": "#00b3bc", "text": "#ffffff"
    // 		}
    // 	},
    // 	"theme": "classic",
    // 	"content": {
    // 		"dismiss": "OK", "link": "Learn More", "href": "https://www.poplarharca.co.uk/cookie-policy/"
    // 	}
    // });

    //VOLUNTEER AJAX CALL AND FILTER DROPDOWN

    $("body").on("click", ".filterTitleMobile", function (event) {
        $(".toggleFilter").slideToggle();
        $(this).toggleClass("open");
    });

    $(window).resize(function () {
        if ($(window).width() > 991) {
            $(".toggleFilter").hide();
            $(".filterTitleMobile").toggleClass("open");
        }
    });

    $("body").on("click", ".filterVolunteerCheckbox", function (event) {
        //event.preventDefault();

        var categoryArray = [];
        var thisLabel = $(this).attr("data-label");
        console.log(thisLabel);

        if ($(this).prop("checked")) {
            $('input[type="checkbox"][data-label="' + thisLabel + '"]').prop(
                "checked",
                true
            );
        } else {
            $('input[type="checkbox"][data-label="' + thisLabel + '"]').prop(
                "checked",
                false
            );
        }

        $("input:checkbox.filterVolunteerCheckbox").each(function () {
            var thisValue = this.checked ? $(this).attr("data-id") : "";
            categoryArray.push(thisValue);
        });

        var data = categoryArray;

        $.ajax({
            type: "POST",
            url: wp_data.adminajax,
            data: {
                action: "volunteerFilter",
                filterID: data,
            },
            dataType: "json",
            encode: true,
        })
            .done(function (response) {
                $("#ajaxResponse").html(response.posts);
            })
            .fail(function () {})
            .always(function () {});
    });

    //way points
    ////statistics
    $(".block-content_statistics").waypoint({
        handler: function (direction) {
            $(this.element).addClass("animated");
        },
        offset: "65%",
    });

    ////sticky header
    var waypoints = $("body").waypoint(
        function (direction) {
            if ($(window).width() > 1199) {
                if (direction == "down") {
                    $("#header, #front-page #alert").addClass("sticky");
                } else if (direction == "up") {
                    $("#header, #front-page #alert").removeClass("sticky");
                }
            }
        },
        {
            offset: "-" + $("#header").outerHeight(),
        }
    );

    //header search bar
    $(".search-submit").on("click", function (event) {
        if ($(this).parent().hasClass("search_closed")) {
            event.preventDefault();
            $(this).parent().removeClass("search_closed");

            setTimeout(function () {
                $(".search-field").focus();
            }, 1300);
        }
    });

    $(".search-field").on("blur", function () {
        setTimeout(function () {
            $(".search-form").addClass("search_closed");
        }, 600);
    });

    //menu button
    $("#header nav.primary #menu-toggle").click(function () {
        $(this).toggleClass("open");
        $("#header nav.primary li.open")
            .removeClass("open")
            .find(".primary_dropdown")
            .slideUp();
        $("#header nav.primary .opened")
            .removeClass("opened")
            .find("ul")
            .slideUp();
        $("#header nav.primary > ul").stop().slideToggle();
    });

    //2nd layer of menu items
    $(".primary_menu_item > a").on("click", function (event) {
        if ($(window).width() < 768) {
            if (!$(this).parent().hasClass("open")) {
                event.preventDefault();
                $(this)
                    .parents()
                    .find("li.open")
                    .removeClass("open")
                    .find(".primary_dropdown")
                    .slideUp();
                $(this)
                    .parent()
                    .addClass("open")
                    .find(".primary_dropdown")
                    .slideDown();
            }
        }
    });

    //3rd layer of menu items
    $(".primary_dropdown_nav li a.has_children").on("click", function (event) {
        if (!$(this).parent().hasClass("opened")) {
            event.preventDefault();
            $(this)
                .parents()
                .find(".opened")
                .removeClass("opened")
                .find("ul")
                .slideUp();
            $(this).parent().addClass("opened").find("ul").slideDown();
        }
    });

    $(".primary_dropdown").on("mouseleave", function () {
        if ($(window).width() >= 992) {
            $(this).find(".opened").removeClass("opened").find("ul").hide();
            $(this).find("a.has_children").blur();
        }
    });

    //Hash menu items
    $('.primary_dropdown_nav li a[href="#"]').on("click", function (event) {
        event.preventDefault();
    });

    //Hash side menu items
    $('.container .menu .sub-menu li a[href="#"]').on(
        "click",
        function (event) {
            event.preventDefault();
            $(this).parent().find(".sub-menu li").stop().slideToggle();
        }
    );

    //projects archive
    $("#archive .tabs:not(.nojs) a").click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass("active")) {
            $(this).parent().find(".active").removeClass("active");
            $(this).addClass("active");
            $(this)
                .parent()
                .parent()
                .find(".list .active")
                .removeClass("active");

            if ($(this).hasClass("live")) {
                $(this)
                    .parent()
                    .parent()
                    .find(".list .live")
                    .addClass("active");
            } else if ($(this).hasClass("completed")) {
                $(this)
                    .parent()
                    .parent()
                    .find(".list .completed")
                    .addClass("active");
            } else if ($(this).hasClass("resale")) {
                $(this)
                    .parent()
                    .parent()
                    .find(".list .resale")
                    .addClass("active");
            }
        }
    });

    $(".block-download_tabs .block-tabs:not(.nojs) a").click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass("active")) {
            $(this).parent().find(".active").removeClass("active");
            $(this).addClass("active");
            $(this)
                .parent()
                .parent()
                .find(".list .active")
                .removeClass("active");

            var tab = $(this).attr("data-tab");
            $(this)
                .parent()
                .parent()
                .find('.list div[data-tab="' + tab + '"]')
                .addClass("active");
        }
    });

    $(
        ".block-download_tabs .block-tabs:not(.nojs) + .list > div > h2.dots"
    ).click(function () {
        if ($(window).width() < 768) {
            $(this)
                .parent()
                .find("> .links")
                .stop()
                .slideToggle(function () {
                    $(this).parent().toggleClass("active");
                });
        }
    });

    $(".block-content_project_timeline .stage").click(function () {
        $(this).parent().find(".current").removeClass("current");
        $(this).addClass("current");
    });

    //block accordion
    $(".block-content_accordion .accordion h3").click(function () {
        $(this).parent().toggleClass("active").find("div").stop().slideToggle();
    });
    $(".block-content_accordion .accordion").keydown(function (event) {
        if (event.type === "keydown" && event.key === "Enter") {
            $(this).toggleClass("active").find("div").stop().slideToggle();
        }
    });

    //faq accordion
    $(".block-content_faqs .faq h3").click(function () {
        $(this).parent().toggleClass("active").find("div").stop().slideToggle();
    });
    $(".block-content_faqs .faq").keydown(function (event) {
        if (event.type === "keydown" && event.key === "Enter") {
            $(this).toggleClass("active").find("div").stop().slideToggle();
        }
    });

    //repairs accordion
    $(".block-content_repairs .repair h3").click(function () {
        $(this).parent().toggleClass("active").find("div").stop().slideToggle();
    });
    $(".block-content_repairs .repair").keydown(function (event) {
        if (event.type === "keydown" && event.key === "Enter") {
            $(this).toggleClass("active").find("div").stop().slideToggle();
        }
    });

    $(".tabs:not(.nojs) + .list > div > h2.dots").click(function () {
        if ($(window).width() < 768) {
            $(this)
                .parent()
                .find("> .row")
                .stop()
                .slideToggle(function () {
                    $(this).parent().toggleClass("active");
                });
        }
    });

    $(".modal-link").click(function (e) {
        e.preventDefault();

        $("html, body").css("overflow", "hidden");

        $(this).next(".modal").fadeIn();
    });

    $(".modal").click(function (e) {
        if ($(".modal").is(e.target) || $(".modal .close").is(e.target)) {
            e.preventDefault();
            $(".modal").fadeOut();

            $("html, body").css("overflow", "auto");
        }
    });

    //$('.frm_forms form .frm_radio label input, .frm_forms form .frm_checkbox label input').after('<span></span>');

    $("#front-page #slideshow").on("init", function (event, slick) {
        $("#front-page #slideshow .slide").each(function (index) {
            var theme = $(this).attr("data-theme");

            $("#front-page #slideshow .slick-dots li")
                .eq(index)
                .addClass(theme);
        });
    });

    $("#front-page #slideshow").slick({
        fade: true,
        dots: true,
        arrows: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        accessibility: true,
        prevArrow: $(".prev-arrow"),
        nextArrow: $(".next-arrow"),
    });

    //.block-content_gallery slider
    if ($(".block-content_gallery").length != 0) {
        $(".block-content_gallery").each(function () {
            $(this)
                .find(".gallery")
                .slick({
                    slidesToShow: 4,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ],
                });
        });
    }

    $(".block-large_gallery .gallery").slick({
        slidesToShow: 1,
        dots: true,
        autoplay: true,
    });

    $(
        ".block-content_text_video .gallery, .block-content_multiple_text_video .gallery"
    ).slick({
        slidesToShow: 1,
        fade: true,
        speed: 500,
        dots: true,
    });

    $(".block-case_studies .gallery").slick({
        slidesToShow: 1,
        fade: true,
        speed: 500,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
    });

    $("#front-page #cards .slider").slick({
        fade: true,
        dots: true,
        arrows: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
    });

    $(".block-organisation_relationship .slider").slick({
        dots: true,
        arrows: true,
        slidesToShow: 4,
        //centerMode: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    $(
        ".block-content_volunteer_op_tabs .tabs a, .block-content_governance_vac_tabs .tabs a"
    ).click(function (e) {
        e.preventDefault();

        if (!$(this).hasClass("active")) {
            $(this).parent().find(".active").removeClass("active");
            $(this).addClass("active");
            $(this)
                .parent()
                .parent()
                .find(".content .active")
                .removeClass("active");

            var title = $(this).attr("data-title");

            $(this)
                .parent()
                .parent()
                .find('.content div[data-title="' + title + '"]')
                .addClass("active");
        }
    });

    ////community centres
    //selecting filters on the community centres page
    $(".filters > .filter").on("click", function () {
        var filter = $(this);

        if (filter.attr("filter") != "activity") {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active");

                $(
                    '.front_page_filter_dropdown[dropdown="' +
                        filter.attr("filter") +
                        '"]'
                ).slideUp();
            } else if ($(this).parent().find(".filter.active").length > 0) {
                var oldFilter = $(this)
                    .parent()
                    .find(".filter.active")
                    .attr("filter");

                $(this).parent().find(".filter.active").removeClass("active");

                $(
                    '.front_page_filter_dropdown[dropdown="' + oldFilter + '"]'
                ).toggle();

                filter.toggleClass("active");

                $(
                    '.front_page_filter_dropdown[dropdown="' +
                        filter.attr("filter") +
                        '"]'
                ).toggle();
            } else {
                filter.addClass("active");

                $(
                    '.front_page_filter_dropdown[dropdown="' +
                        filter.attr("filter") +
                        '"]'
                ).slideDown();
            }
        } else {
            if ($(this).parent().find(".filter.active").length > 0) {
                var oldFilter = $(this)
                    .parent()
                    .find(".filter.active")
                    .attr("filter");

                $(this).parent().find(".filter.active").removeClass("active");

                $(
                    '.front_page_filter_dropdown[dropdown="' + oldFilter + '"]'
                ).slideUp();
            }
        }
    });

    //scroll to activities
    $('.filter[filter="activity"]').click(function (event) {
        var target = $(".activity_types");

        if (target.length) {
            event.preventDefault();
            $("html, body").animate(
                {
                    scrollTop: target.offset().top - 80,
                },
                800
            );
        }
    });

    //On activity archive page
    $(".filter_option").on("click", function () {
        var dropdown = $(this).attr("filter");

        if (
            !$(this).hasClass("opened") &&
            $(this).parent().find(".opened").length > 0
        ) {
            var oldDropDown = $(this).parent().find(".opened").attr("filter");
            $(this)
                .parent()
                .find('.filter_dropdown[dropdown="' + oldDropDown + '"]')
                .slideToggle(function () {
                    $(this)
                        .parent()
                        .find('.filter_dropdown[dropdown="' + dropdown + '"]')
                        .slideToggle();
                });

            $(this)
                .parent()
                .find('.filter_option[filter="' + oldDropDown + '"]')
                .removeClass("opened");
        } else {
            $(this)
                .parent()
                .find('.filter_dropdown[dropdown="' + dropdown + '"]')
                .slideToggle();
        }

        $(this).toggleClass("opened");
    });

    $(".toggle_list_map").on("click", function () {
        if (!$(this).hasClass("active")) {
            $(this)
                .parent()
                .find($(".toggle_list_map.active"))
                .removeClass("active");
            $(this).addClass("active");

            $(".activity_articles").toggle();
            $(".map_container").toggle();
        }
    });

    //Hovering on Activities on the community centres page
    $(".activity_type").hover(
        function () {
            var height = $(this).find(".activity_type_icon").height();
            var width = $(this).find(".activity_type_icon").width();

            $(this)
                .find(".underlay")
                .stop()
                .animate({
                    top: 15,
                    width: width - 36,
                    height: width - 36,
                    opacity: "1",
                });
        },
        function () {
            var height = $(this).find(".activity_type_icon").height();
            var width = $(this).find(".activity_type_icon").width();

            $(this)
                .find(".underlay")
                .stop()
                .animate({
                    top: height / 2,
                    width: "0",
                    height: "0",
                    opacity: "0",
                });
        }
    );

    //maps
    if ($("#map").length) {
        initMap();
    }

    if ($("#community_center_map").length) {
        initCommunityMap();
    }

    $(
        ".block-organisation_relationship .slide .bg, .block-organisation_relationship .bg-grey2 .bg-white"
    ).hover(function () {
        if ($(window).width() >= 992) {
            $(this).find("p").stop().slideToggle();
        } else {
            $(this).find("p").stop().slideDown();
        }
    });

    $(
        "#archive-story #archive .list a, #archive-covid-story #archive .list a"
    ).click(function (e) {
        if (!$(this).hasClass("hover") && $(window).width() < 1200) {
            e.preventDefault();
            $("#archive .list a.hover").removeClass("hover");
            $(this).addClass("hover");
        }
    });

    $(document).on("click", function (e) {
        if (
            !$(
                "#archive-story #archive .list a, #archive-covid-story #archive .list a"
            ).is(e.target) &&
            !$(
                "#archive-story #archive .list a, #archive-covid-story #archive .list a"
            ).has(e.target).length
        ) {
            $(
                "#archive-story #archive .list a.hover, #archive-covid-story #archive .list a.hover"
            ).removeClass("hover");
        }
    });

    //Consent Magic footer pop-up
    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });
});

function initMap() {
    var marks = jQuery("#map .marker");
    var setlat = Number(jQuery("#map").data("lat"));
    var setlng = Number(jQuery("#map").data("lng"));
    var setzoom = jQuery("#map").data("zoom");

    if (isNaN(setlat) || setlat === undefined) setlat = 0;
    if (isNaN(setlng) || setlng === undefined) setlng = 0;
    if (setzoom == "" || setzoom == "auto" || setzoom === undefined)
        setzoom = 0;

    var map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: setlat, lng: setlng },
        zoom: setzoom,
        minZoom: 8,
        maxZoom: 17,
        zoomControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
            {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
        ],
    });

    var bounds = new google.maps.LatLngBounds();
    map.markers = [];
    var infowindow = new google.maps.InfoWindow();

    marks.each(function () {
        var element = jQuery(this);
        var latlng = new google.maps.LatLng(
            element.data("lat"),
            element.data("lng")
        );

        var icon = {
            url: wp_data.stylesheet_uri + "/assets/marker-pink.png",
            scaledSize: new google.maps.Size(34, 44),
        };

        var current = element.data("current");
        var address = element.data("address");
        var link = element.data("link");
        var label = element.data("linklabel");
        var url = element.data("url");

        var text = "";

        if (current !== "current") {
            current = "";
            icon = {
                url: wp_data.stylesheet_uri + "/assets/marker-grey.png",
                scaledSize: new google.maps.Size(34, 44),
            };
        }

        var mark = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: icon,
        });

        if (url != "" && url !== undefined && current !== "current") {
            text =
                '<div class="infobox ' +
                current +
                '"><h3>' +
                element.data("title") +
                '</h3><a class="button bg-white" href="' +
                element.data("url") +
                '">Read More</a>';
        } else {
            text =
                '<div class="infobox ' +
                current +
                '"><h3>' +
                element.data("title") +
                "</h3>";
        }

        if (address != "" && address !== undefined) {
            text += "<p>" + address + "</p>";
        }

        if (
            link != "" &&
            label != "" &&
            link !== undefined &&
            label !== undefined
        ) {
            text +=
                '<a class="button bg-white" target="_blank" href="' +
                link +
                '">' +
                label +
                "</a>";
        }

        text += "</div>";

        map.markers.push(mark);

        bounds.extend(latlng);

        google.maps.event.addListener(mark, "click", function () {
            infowindow.close();
            infowindow.setContent(text);
            infowindow.open(map, mark);
        });
    });

    if (setlat == 0 || setlng == 0 || setzoom == 0) {
        map.fitBounds(bounds);
    }

    return map;
}

//community centre map - maybe a good idea to tidy this up later
function centerMap(map) {
    var bounds = new google.maps.LatLngBounds();

    jQuery.each(map.markers, function (i, marker) {
        var latlng = new google.maps.LatLng(
            marker.position.lat(),
            marker.position.lng()
        );
        bounds.extend(latlng);
    });

    if (map.markers.length > 0) {
        map.setCenter(bounds.getCenter());
    }

    map.setZoom(14);
}

function initCommunityMap() {
    var currentUrl = window.location.href;

    var marks = jQuery("#community_center_map .marker");

    var map = new google.maps.Map(
        document.getElementById("community_center_map"),
        {
            zoom: 14,
            center: { lat: 51.5123643, lng: -0.0228384 },
        }
    );

    map.markers = [];

    var infowindow = new google.maps.InfoWindow();

    marks.each(function () {
        var element = jQuery(this);
        var latlng = new google.maps.LatLng(
            element.data("lat"),
            element.data("lng")
        );
        var mark = new google.maps.Marker({
            position: latlng,
            map: map,
        });

        if (currentUrl.includes("neighbourhood-action-plans")) {
            mark.setIcon(
                "/wp-content/themes/poplarharca/assets/marker-blue.svg"
            );
        } else {
            mark.setIcon(
                "/wp-content/themes/poplarharca/assets/marker-orange.png"
            );
        }

        var text = element.html();
        var count = 1;

        map.markers.push(mark);

        if (map.markers.length != 0) {
            for (i = 0; i < map.markers.length; i++) {
                var existingMarker = map.markers[i];
                var pos = existingMarker.getPosition();

                if (latlng.equals(pos)) {
                    count++;
                }
            }
        }

        if (count > 1) {
            text = '<div class="infobox">' + element.html() + "</div>";
        }

        google.maps.event.addListener(mark, "click", function () {
            infowindow.close();
            infowindow.setContent(text);
            infowindow.open(map, mark);
        });
    });

    google.maps.event.addListener(infowindow, "domready", function () {
        var iwcontainer = jQuery(".gm-style-iw");

        iwcontainer.prev().css({ display: "none" });

        iwcontainer
            .next()
            .css({
                "font-weight": "300",
                color: "#fff",
                "font-size": "22px",
                padding: "0",
                "text-align": "center",
                width: "20px",
                height: "25px",
                background: "none",
                "pointer-events": "auto",
                cursor: "pointer",
                opacity: "1",
            })
            .html("x");
    });

    centerMap(map);
}
