const $ = jQuery;

export default class Neighbourhood {
    constructor(lat, lng, apiUrl) {
        this.Geometry = "";
        this.lat = lat;
        this.lng = lng;
        this.apiUrl = apiUrl;
        this.geoJsonArray = [];
    }

    async __loadGoogleMapsLibrary() {
        const { Geometry } = await google.maps.importLibrary("geometry");
        this.Geometry = Geometry;
    }

    async __fetchGeoJson() {
        try {
            const response = await fetch(this.apiUrl);

            if (!response.ok) {
                throw new Error("Failed to fetch GeoJSON data.");
            }

            const data = await response.json();
            this.geoJsonArray = data;
        } catch (error) {
            console.error("Error fetching GeoJSON:", error);
            this.geoJsonArray = [];
        }
    }

    async init() {
        await this.__loadGoogleMapsLibrary();
        await this.__fetchGeoJson();
        return this.__isPointInAnyPolygon(this.lat, this.lng);
    }

    // Function to check if a point (latitude and longitude) is within any of the given polygons
    __isPointInAnyPolygon(lat, lng) {
        const point = new google.maps.LatLng(lat, lng);

        for (let i = 0; i < this.geoJsonArray.length; i++) {
            const polygon = this.geoJsonArray[i];
            const polygonCoords =
                polygon.features[0].geometry.coordinates[0].map(
                    (coord) => new google.maps.LatLng(coord[1], coord[0])
                );

            if (
                google.maps.geometry.poly.containsLocation(
                    point,
                    new google.maps.Polygon({ paths: polygonCoords })
                )
            ) {
                return {
                    isInside: true,
                    polygonIndex: i,
                    polygonId: polygon.features[0].properties.id,
                    polygonPermalink: polygon.features[0].properties.permalink,
                    polygonName: polygon.features[0].properties.name,
                };
            }
        }

        return { isInside: false, message: "Point is not within any area." };
    }
}
